<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Multiselect from "vue-multiselect";
import DatePicker from "vue2-datepicker";
import Swal from "sweetalert2";
import useNetw from "@useNetw";
import btnComponent from "@/components/btnComponent";
import tableData from "@/components/tablecomponent";

export default {
  page: {
    title: "หน้าหลัก",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    Multiselect,
    DatePicker,
    btnComponent,
    tableData,
  },
  data() {
    return {
      title: "พิมพ์รายงาน",
      items: [
        {
          text: "พิมพ์รายงาน",
        },
        {
          text: "พิมพ์รายงาน",
          active: true,
        },
      ],
      contactOption: [
        {
          nameTh: "3 วัน",
          id: "3",
        },
        {
          nameTh: "1 เดือน",
          id: "1",
        },
      ],
      ctJobTypeId: "",
      loading: false,
      overlayFlag: false,
      rowData: [],
      localData: "",
      localDataBranch: [],
      contactTypeOption: [],
      localProgram: [],
      Program: [],
      ProgramLVL2: [],
      ProgramLVL3: [],
      userData: "",
      dataReport: [],
      contactTypeId: "",
      //? multi store
      optionMasterST: [],
      masterST: [],

      //? multi Sv
      rowSv: [],
      paramData: {},

      //? params
      newDate: "",
      branchId: [],
      date: "",
      svId: "",
      startDate: "",
      endDate: "",
      dateSale: "",
      contact: "",
      //? show params
      contactShow: false,
      saleDate: false,
      btnShow: false,
      btnSearch: false,
      branchIdShow: false,
      dateShow: false,
      rowSvShow: false,
      showTabeComponent: false,
      stockIdShow: false,
      startDateShow: false,
      endDateShow: false,
      contactType: false,
      fields: [],

      reportData: [
        {
          reportId: "REP0000004",
          nameTh: "รายงานการนัดประจำวัน",
          link: "api/appointment/print",
          parent: "CRM03000",
          params: {},
        },
        {
          reportId: "REP0000002",
          nameTh: "ตารางการนัดหมายประจำวัน",
          link: "api/appointment/print-flow-slot",
          parent: "CRM04000",
          params: [],
        },
        {
          reportId: "REP0000005",
          nameTh: "ตารางวางแผนงานซ่อม (Flow slot)",
          link: "api/flow-slot/print-flow-slot",
          parent: "SRV05000",
          params: [],
        },
        {
          reportId: "REP0000003",
          nameTh: "ใบ Service Inspection",
          apiGetlink: "api/service/inspection",
          link: "api/service/inspection/print",
          parent: "SRV06000",
          params: [],
        },
        {
          reportId: "REP0000001",
          nameTh: "ใบแจ้งซ่อม (RO)",
          apiGetlink: "api/service/ro",
          link: "api/service/ro/print",
          parent: "SRV06000",
          params: [],
        },
        {
          reportId: "REP0000006",
          nameTh: "ใบสั่งซื้ออะไหล่",
          apiGetlink: "api/part-po",
          link: "api/part-po/print",
          parent: "PRT02000",
          params: [],
        },

        {
          reportId: "REP0000008",
          nameTh: "ใบรับอะไหล่เข้าคลัง",
          apiGetlink: "api/part-receipt",
          link: "api/part-receipt/print",
          parent: "PRT03000",
          params: [],
        },
        {
          reportId: "REP0000009",
          nameTh: "ใบเบิกอะไหล่",
          apiGetlink: "api/part-requisition",
          link: "api/part-requisition/print",
          parent: "PRT04000",
          params: [],
        },
        {
          reportId: "REP0000010",
          nameTh: "ใบจ่ายอะไหล่",
          apiGetlink: "api/part-picking",
          link: "api/part-picking/print",
          parent: "PRT05000",
          params: [],
        },
        {
          reportId: "REP0000011",
          nameTh: "ใบคืนอะไหล่",
          apiGetlink: "api/part-return",
          link: "api/part-return/print",
          parent: "PRT06000",
          params: [],
        },
        {
          reportId: "REP0000013",
          nameTh: "รายงานรถเข้าซ่อมทั้งหมด",
          apiGetlink: "",
          link: "api/report/ro-repair",
          parent: "SRV05000",
          params: [],
        },
        {
          reportId: "REP0000015",
          nameTh: "รายงานสินค้าคงเหลือ",
          apiGetlink: "api/master/stock-part",
          link: "api/report/part-stock",
          parent: "PRT07000",
          params: [],
        },
        {
          reportId: "REP0000016",
          nameTh: "รายงานรายได้จากการซ่อม",
          apiGetlink: "",
          link: "api/report/ro-income",
          parent: "SRV06000",
          params: [],
        },
        {
          reportId: "SV-TINV-01",
          nameTh: "ใบกำกับภาษี/ใบเสร็จรับเงิน",
          apiGetlink: "api/tax-invoice",
          link: "api/tax-invoice/print",
          parent: "FIN01000",
          params: [],
        },
        // {
        //   reportId: "REP0000022",
        //   nameTh: "รายงานติดตาม หลังการขาย",
        //   apiGetlink: "",
        //   link: "",
        //   parent: "CRM05000",
        //   params: [],
        // },
        {
          reportId: "REP0000020",
          nameTh: "รายงานลูกค้ามุ่งหวัง",
          apiGetlink: "",
          link: "api/prospect-customer/print",
          parent: "CRM05000",
          params: [],
        },
        {
          reportId: "REP0000021",
          nameTh: "รายงานการติดต่อ",
          apiGetlink: "",
          link: "api/contact-job/print",
          parent: "CRM06000",
          params: [],
        },
        {
          reportId: "REP0000024",
          nameTh: "รายงานการตรวจสอบสถานะเอกสาร",
          apiGetlink: "",
          link: "api/report/reg-doc",
          parent: "REG09000",
          params: [],
        },
        {
          reportId: "REP0000025",
          nameTh: "รายงานสรุปการขอนุมัติเดินทาง",
          apiGetlink: "",
          link: "api/report/travel-expenses",
          parent: "APV01002",
          params: [],
        },
        {
          reportId: "REP0000026",
          nameTh: "รายงานการติดตาม(ฝ่ายขาย)",
          apiGetlink: "",
          link: "api/report/contact-sale",
          parent: "SEL01001",
          params: [],
        },
        {
          reportId: "REP0000030",
          nameTh: "รายงานการสํารวจตลาด",
          apiGetlink: "",
          link: "api/report/mk-scan",
          parent: "CRM15000",
          params: [],
        },
      ],
    };
  },
  methods: {
    getData: function () {
      this.overlayFlag = true;
      if (this.dataReport.reportId == "REP0000003") {
        this.paramData = {
          page: 1,
          perPage: 100,
          branchId: [this.branchId.branchId],
          svId: this.svId.svId,
        };
        this.fields = [
          {
            key: "inspCode",
            label: "รหัสใบ Inspection",
          },
          {
            key: "action",
            label: "พิมพ์",
          },
        ];
      } else if (this.dataReport.reportId == "REP0000001") {
        this.paramData = {
          page: 1,
          perPage: 100,
          branchId: [this.branchId.branchId],
          svId: this.svId.svId,
        };
        this.fields = [
          {
            key: "roCode",
            label: "รหัสใบ RO",
          },
          {
            key: "action",
            label: "พิมพ์",
          },
        ];
      } else if (this.dataReport.reportId == "REP0000006") {
        this.paramData = {
          page: 1,
          perPage: 100,
          branchId: [this.branchId.branchId],
          poDate: this.date,
        };
        this.fields = [
          {
            key: "poCode",
            label: "รหัสใบ PO",
          },
          {
            key: "action",
            label: "พิมพ์",
          },
        ];
      } else if (this.dataReport.reportId == "REP0000008") {
        this.paramData = {
          page: 1,
          perPage: 100,
          branchId: [this.branchId.branchId],
          receiptDate: this.date,
        };
        this.fields = [
          {
            key: "receiptCode",
            label: "รหัสใบรับอะไหล่",
          },
          {
            key: "action",
            label: "พิมพ์",
          },
        ];
      } else if (this.dataReport.reportId == "REP0000009") {
        this.paramData = {
          page: 1,
          perPage: 100,
          branchId: [this.branchId.branchId],
          reqDate: this.date,
        };
        this.fields = [
          {
            key: "reqCode",
            label: "รหัสใบเบิก",
          },
          {
            key: "action",
            label: "พิมพ์",
          },
        ];
      } else if (this.dataReport.reportId == "REP0000010") {
        this.paramData = {
          page: 1,
          perPage: 100,
          branchId: [this.branchId.branchId],
          pickingDate: this.date,
        };
        this.fields = [
          {
            key: "pickingCode",
            label: "รหัสใบรับ",
          },
          {
            key: "action",
            label: "พิมพ์",
          },
        ];
      } else if (this.dataReport.reportId == "REP0000011") {
        this.paramData = {
          page: 1,
          perPage: 100,
          branchId: [this.branchId.branchId],
          returnDate: this.date,
        };
        this.fields = [
          {
            key: "pickingCode",
            label: "รหัสใบคืน",
          },
          {
            key: "action",
            label: "พิมพ์",
          },
        ];
      } else if (this.dataReport.reportId == "SV-TINV-01") {
        this.paramData = {
          page: 1,
          perPage: 100,
          branchId: [this.branchId.branchId],
          // roCode: this.filter.roCode,
          // customerNameTh: this.filter.customerNameTh,
          // taxInvCode: this.filter.taxInvCode,
          // licensePlate: this.filter.licensePlate,
        };
        this.fields = [
          {
            key: "taxInvCode",
            label: "รหัสใบกำกับภาษี",
          },
          {
            key: "action",
            label: "พิมพ์",
          },
        ];
      }
      useNetw
        .get(this.dataReport.apiGetlink, {
          params: this.paramData,
        }) // ?เอามาจากไหน

        .then((response) => {
          this.rowData = response.data.data;
          // // console.log(this.rowData);
          this.showTabeComponent = true;
        })
        .catch((err) => {
          //?ถ้าดึงข้อมูลไม่ได้ให้ทำอะไรไม่ได้ให้ทำอะไรฃ
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              appConfig.swal.type.error
            );
            this.$router.push("/login");
          }
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },
    getReportTb(data) {
      // // console.log(this.dataReport.link);
      this.overlayFlag = true;
      if (this.dataReport.reportId == "REP0000003") {
        this.params = {
          inspId: data.inspId,
          svId: this.svId.svId,
        };
      } else if (this.dataReport.reportId == "REP0000001") {
        this.params = {
          roId: data.roId,
          svId: this.svId.svId,
        };
      } else if (this.dataReport.reportId == "REP0000006") {
        this.params = {
          poId: data.poId,
        };
      } else if (this.dataReport.reportId == "REP0000008") {
        this.params = {
          receiptId: data.receiptId,
        };
      } else if (this.dataReport.reportId == "REP0000009") {
        this.params = {
          reqId: data.reqId,
        };
      } else if (this.dataReport.reportId == "REP0000010") {
        this.params = {
          pickingId: data.pickingId,
        };
      } else if (this.dataReport.reportId == "REP0000011") {
        this.params = {
          returnId: data.returnId,
        };
      } else if (this.dataReport.reportId == "SV-TINV-01") {
        this.params = {
          taxInvId: data.taxInvId,
        };
      } else if (this.dataReport.reportId == "REP0000015") {
        this.params = {
          stockId: this.masterST.stockId,
        };
      } else if (this.dataReport.reportId == "REP0000013") {
        this.params = {
          branchId: this.branchId.branchId,
          startDate: this.startDate,
          endDate: this.endDate,
        };
      } else if (this.dataReport.reportId == "REP0000016") {
        this.params = {
          branchId: this.branchId.branchId,
          startDate: this.startDate,
          endDate: this.endDate,
        };
      } else if (this.dataReport.reportId == "REP0000020") {
        this.params = {
          branchId: this.branchId.branchId,
          startDate: this.startDate,
          endDate: this.endDate,
        };
      } else if (this.dataReport.reportId == "REP0000021") {
        this.params = {
          branchId: this.branchId.branchId,
          ctJobDate: this.dateSale,
        };
      } else if (this.dataReport.reportId == "REP0000024") {
        this.params = {
          startDate: this.startDate,
          endDate: this.endDate,
        };
      } else if (this.dataReport.reportId == "REP0000025") {
        this.params = {
          startDate: this.startDate,
          endDate: this.endDate,
        };
      } else if (this.dataReport.reportId == "REP0000025") {
        this.params = {
          branchId: this.branchId.branchId,
          startDate: this.startDate,
          endDate: this.endDate,
        };
      } else if (this.dataReport.reportId == "REP0000026") {
        this.params = {
          startDate: this.startDate,
          endDate: this.endDate,
          ctJobTypeId: this.ctJobTypeId ? this.ctJobTypeId.ctJobTypeId : "",
        };
      } else if (this.dataReport.reportId == "REP0000030") {
        this.params = {
          startDate: this.startDate,
          endDate: this.endDate,
          branchId: this.branchId.branchId,
        };
      }
      useNetw
        .get(this.dataReport.link, {
          params: this.params,
        }) // ?เอามาจากไหน
        .then((data) => {
          // console.log(data.data.url);
          window.location = data.data.url;
        })
        .catch((err) => {
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify("กรุณาเลือกสาขา"),
              appConfig.swal.type.error
            );
            this.$router.push("/login");
          } else {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify("ไม่พบข้อมูล"),
              appConfig.swal.type.error
            );
          }
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },
    getMasterPartST: function () {
      // this.rowParts = [];
      this.optionMasterST = [];
      this.masterST = [];
      // this.overlayFlag = true;
      this.loading = true; //skeleton true
      useNetw
        .get("api/master/stock-part", {
          params: {
            branchId: this.branchId.branchId,
          },
        })
        .then((response) => {
          // console.log("Master Stock ==> ", response.data.data);
          this.optionMasterST = response.data.data;
          // // console.log(response);
        })
        .catch((err) => {
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              appConfig.swal.type.error
            );
            this.$router.push("/login");
          } else {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(err.message + "<br>" + err.response.data.message),
              appConfig.swal.type.error
            );
          }
        })
        .then(() => {
          // this.overlayFlag = false;
          this.loading = false; //skeleton false
        });
    },
    getReport() {
      // // console.log("Success getReport");
      this.overlayFlag = true;
      if (
        this.dataReport.reportId == "REP0000004" ||
        this.dataReport.reportId == "REP0000002"
      ) {
        this.params = {
          branchId: this.branchId.branchId,
          apptDate: this.date,
        };
      } else if (this.dataReport.reportId == "REP0000005") {
        this.params = {
          branchId: this.branchId.branchId,
          date: this.date,
        };
      } else if (this.dataReport.reportId == "REP0000015") {
        this.params = {
          // branchId: this.branchId.branchId,
          stockId: this.masterST.stockId,
        };
      } else if (this.dataReport.reportId == "REP0000013") {
        this.params = {
          branchId: this.branchId.branchId,
          startDate: this.startDate,
          endDate: this.endDate,
        };
      } else if (this.dataReport.reportId == "REP0000016") {
        this.params = {
          branchId: this.branchId.branchId,
          startDate: this.startDate,
          endDate: this.endDate,
        };
      } else if (this.dataReport.reportId == "REP0000020") {
        this.params = {
          branchId: this.branchId.branchId,
          startDate: this.startDate,
          endDate: this.endDate,
        };
      } else if (this.dataReport.reportId == "REP0000021") {
        this.params = {
          branchId: this.branchId.branchId,
          ctJobDate: this.dateSale,
        };
      } else if (this.dataReport.reportId == "REP0000024") {
        this.params = {
          startDate: this.startDate,
          endDate: this.endDate,
        };
      } else if (this.dataReport.reportId == "REP0000025") {
        this.params = {
          branchId: this.branchId.branchId,

          startDate: this.startDate,
          endDate: this.endDate,
        };
      } else if (this.dataReport.reportId == "REP0000026") {
        this.params = {
          startDate: this.startDate,
          endDate: this.endDate,
          ctJobTypeId: this.ctJobTypeId ? this.ctJobTypeId.ctJobTypeId : "",
        };
      } else if (this.dataReport.reportId == "REP0000030") {
        this.params = {
          startDate: this.startDate,
          endDate: this.endDate,
          branchId: this.branchId.branchId,
        };
      }
      useNetw
        .get(this.dataReport.link, {
          params: this.params,
        }) // ?เอามาจากไหน
        .then((data) => {
          this.linkPtintFlowslot = data.data.url;
          window.location = data.data.url;
          this.$refs["modalPrintflowSlot"].hide();
        })
        .catch((err) => {
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify("กรุณาเลือกสาขา"),
              appConfig.swal.type.error
            );
            this.$router.push("/login");
          } else {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify("ไม่พบข้อมูล"),
              appConfig.swal.type.error
            );
          }
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },
    handleHide() {
      this.dateShow = false;
      this.branchIdShow = false;
      this.btnShow = false;
      this.btnSearch = false;
      this.rowSvShow = false;
      this.stockIdShow = false;
      this.contactShow = false;
      this.saleDate = false;
      this.startDateShow = false;
      this.endDateShow = false;
    },
    popupModalPrint(dataReport) {
      this.$refs.modalPrint.show();
      this.dataReport = dataReport;

      if (
        this.dataReport.reportId == "REP0000004" ||
        this.dataReport.reportId == "REP0000002"
      ) {
        // // console.log("print =>", dataReport.nameTh);
        this.btnShow = true;
        this.dateShow = true;
        this.branchIdShow = true;
      } else if (this.dataReport.reportId == "REP0000005") {
        this.btnShow = true;
        this.dateShow = true;
        this.branchIdShow = true;
      } else if (
        this.dataReport.reportId == "REP0000003" ||
        this.dataReport.reportId == "REP0000001"
      ) {
        this.btnSearch = true;
        this.dateShow = true;
        this.branchIdShow = true;
        this.rowSvShow = true;
      } else if (this.dataReport.reportId == "REP0000006") {
        this.btnSearch = true;
        this.dateShow = true;
        this.branchIdShow = true;
      } else if (this.dataReport.reportId == "REP0000008") {
        this.btnSearch = true;
        this.dateShow = true;
        this.branchIdShow = true;
      } else if (this.dataReport.reportId == "REP0000009") {
        this.btnSearch = true;
        this.dateShow = true;
        this.branchIdShow = true;
      } else if (this.dataReport.reportId == "REP0000010") {
        this.btnSearch = true;
        this.dateShow = true;
        this.branchIdShow = true;
      } else if (this.dataReport.reportId == "REP0000011") {
        this.btnSearch = true;
        this.dateShow = true;
        this.branchIdShow = true;
      } else if (this.dataReport.reportId == "SV-TINV-01") {
        this.btnSearch = true;
        this.dateShow = true;
        this.branchIdShow = true;
      } else if (this.dataReport.reportId == "REP0000015") {
        // this.btnSearch = true;
        this.btnShow = true;
        this.branchIdShow = true;
        this.stockIdShow = true;
      } else if (this.dataReport.reportId == "REP0000013") {
        // this.btnSearch = true;
        this.branchIdShow = true;
        this.btnShow = true;
        this.startDateShow = true;
        this.endDateShow = true;
      } else if (this.dataReport.reportId == "REP0000016") {
        // this.btnSearch = true;
        this.branchIdShow = true;
        this.btnShow = true;
        this.startDateShow = true;
        this.endDateShow = true;
      } else if (this.dataReport.reportId == "REP0000022") {
        // this.btnSearch = true;
        this.branchIdShow = true;
        this.btnShow = true;
        this.saleDate = true;
        this.contactShow = true;
      } else if (this.dataReport.reportId == "REP0000020") {
        // this.btnSearch = true;
        this.branchIdShow = true;
        this.btnShow = true;
        this.startDateShow = true;
        this.endDateShow = true;
      } else if (this.dataReport.reportId == "REP0000021") {
        // this.btnSearch = true;
        this.branchIdShow = true;
        this.btnShow = true;
        this.saleDate = true;
      } else if (this.dataReport.reportId == "REP0000024") {
        // this.btnSearch = true;
        this.btnShow = true;
        this.startDateShow = true;
        this.endDateShow = true;
      } else if (this.dataReport.reportId == "REP0000025") {
        // this.btnSearch = true;
        this.btnShow = true;
        this.branchIdShow = true;
        this.startDateShow = true;
        this.endDateShow = true;
      } else if (this.dataReport.reportId == "REP0000026") {
        // this.btnSearch = true;
        this.btnShow = true;
        this.contactShow = true;
        this.startDateShow = true;
        this.endDateShow = true;
      } else if (this.dataReport.reportId == "REP0000030") {
        // this.btnSearch = true;
        this.btnShow = true;
        this.branchIdShow = true;
        this.startDateShow = true;
        this.endDateShow = true;
      }
    },
    getLocalData() {
      const user = JSON.parse(localStorage.getItem(appConfig.storageUserKey));
      this.userData = user;
      this.localData = user.userAccessBranch;
      this.localProgram = user.userAccessProgram;
      this.branchId = user.branchId;
      const localDataBranch = [];
      const locoalDataProgram = [];
      const locoalDataProgramLVL2 = [];
      const locoalDataProgramLVL3 = [];
      this.localData.forEach((item) => {
        localDataBranch.push(item.branchId);
      });
      this.localProgram
        .filter((item) => item.lvl == "1")
        .forEach((item) => {
          locoalDataProgram.push(item);
        });
      this.localProgram
        .filter((item) => item.lvl == "2")
        .forEach((item) => {
          locoalDataProgramLVL2.push(item);
        });
      this.localProgram
        .filter((item) => item.lvl == "3")
        .forEach((item) => {
          locoalDataProgramLVL3.push(item);
        });

      this.Program = locoalDataProgram;
      this.ProgramLVL2 = locoalDataProgramLVL2;
      this.ProgramLVL3 = locoalDataProgramLVL3;
      // // console.log(this.ProgramLVL2);
    },
    getContactType: function () {
      // this.overlayFlag = true;
      this.loading = true; //skeleton true
      useNetw
        .get("api/master/contact-job-types-c", {})
        .then((response) => {
          this.contactTypeOption = response.data.data;
        })
        .catch((err) => {
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              // appConfig.swal.title.Unauthorization,
              appConfig.swal.type.error
              // appConfig.swal.title.Unauthorization
            );
            this.$router.push("/login");
          } else {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(err.message + "<br>" + err.response.data.message),
              appConfig.swal.type.error
            );
          }
        })
        .then(() => {
          // this.overlayFlag = false;
          this.loading = false; //skeleton false
        });
    },

    getDataSv() {
      useNetw
        .get("api/service", {
          params: {
            page: 1,
            perPage: 100,
            branchId: [this.branchId],
          },
        })
        .then((response) => {
          this.rowSv = response.data.data;
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.overlayFlag = false;
          this.isLoading = false;
        });
    },
    calDate(contact) {
      const theDate = new Date(this.dateSale);
      const myNewDate = new Date(theDate);
      if (contact.id == "3") {
        myNewDate.setDate(myNewDate.getDate() - contact.id);
      } else if (contact.id == "1") {
        myNewDate.setMonth(myNewDate.getMonth() - contact.id);
      }
      this.newDate = this.change(myNewDate);
    },
    change(dateChange) {
      const today = new Date(dateChange);
      const dd = String(today.getDate()).padStart(2, "0");
      const mm = String(today.getMonth() + 1).padStart(2, "0"); // January is 0!
      const yyyy = today.getFullYear();
      const date = `${yyyy}-${mm}-${dd}`;
      const changeDate = date;
      return changeDate;
    },
  },
  created() {
    this.getLocalData();
    this.getDataSv();
    this.getContactType();
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <b-overlay
      :show="overlayFlag"
      :spinner-variant="this.$overlayVariant"
      :spinner-type="this.$overlayType"
      :rounded="this.$overlayRounded"
    >
      <div class="row">
        <div class="card">
          <div class="card-body">
            <div
              v-for="(itemlvl1, indexlvl1) in Program.filter(
                (item) =>
                  item.programId != 'AHOME' && item.programId != 'REP00000'
              )"
              :key="indexlvl1"
            >
              <h5>{{ indexlvl1 + 1 }}. รายงาน{{ itemlvl1.nameTh }}</h5>
              <div
                v-for="(itemlvl2, indexlvl2) in ProgramLVL2.filter(
                  (item) => item.parentProgramId === itemlvl1.programId
                )"
                :key="indexlvl2"
              >
                <div
                  v-for="(itemReport, indexReport) in reportData.filter(
                    (item) => item.parent === itemlvl2.programId
                  )"
                  :key="indexReport"
                >
                  <b
                    ><a @click="popupModalPrint(itemReport)"
                      >&nbsp;&nbsp; -
                      <u>
                        [ {{ itemReport.reportId }} ] พิมพ์{{
                          itemReport.nameTh
                        }}</u
                      ></a
                    ></b
                  >
                </div>
              </div>

              <hr />
            </div>
          </div>
        </div>
      </div>
      <b-modal
        ref="modalPrint"
        :title="dataReport.nameTh"
        hide-footer
        size="lg"
        @hide="handleHide"
      >
        <div class="row">
          <div class="col-md-6 col-sm-6" v-show="branchIdShow">
            <label for="branchId">สาขา :</label>
            <multiselect
              id="branchId"
              v-model="branchId"
              :options="localData"
              track-by="nameTh"
              label="nameTh"
              :show-labels="false"
              @input="getMasterPartST"
            ></multiselect>
          </div>
          <div class="col-md-6 col-sm-6" v-show="contactType">
            <label for="branchId">ประเภทการติดต่อ :</label>
            <multiselect
              id="branchId"
              v-model="contactTypeId"
              :options="contactTypeOption"
              track-by="nameTh"
              label="nameTh"
              :show-labels="false"
            ></multiselect>
          </div>
          <div class="col-md-3" v-show="dateShow">
            <label for="validation">วันที่เข้ารับบริการ :</label>
            <date-picker
              v-model="date"
              format="YYYY-MM-DD"
              value-type="format"
            ></date-picker>
          </div>

          <div class="col-md-4 col-sm-6" v-show="saleDate">
            <label for="validation">วันที่ :</label>
            <date-picker
              v-model="dateSale"
              format="YYYY-MM-DD"
              value-type="format"
            ></date-picker>
          </div>
          <div class="col-md-4 col-sm-6" v-show="contactShow">
            <div class="position-relative">
              <label for="contact"> การติดตาม :</label>
              <multiselect
                id="contact"
                v-model="ctJobTypeId"
                :options="contactTypeOption"
                :show-labels="false"
                label="nameTh"
                track-by="nameTh"
              >
                <span slot="noResult">ไม่พบข้อมูล</span></multiselect
              >
            </div>
          </div>
          <div class="col-md-6" v-show="rowSvShow">
            <label for="branchId">SV :</label>
            <multiselect
              id="branchId"
              v-model="svId"
              :options="rowSv"
              track-by="nameTh"
              label="svCode"
              :show-labels="false"
            ></multiselect>
          </div>
          <div class="col-md-6" v-show="stockIdShow">
            <div class="mb-3 position-relative">
              <code> * </code>
              <label for="branchId">คลัง :</label><br />
              <multiselect
                v-model="masterST"
                :options="optionMasterST"
                label="nameTh"
              >
              </multiselect>
            </div>
          </div>
          <div class="col-md-3" v-show="startDateShow">
            <label for="validation">เริ่มวันที่ :</label>
            <date-picker
              v-model="startDate"
              format="YYYY-MM-DD"
              value-type="format"
            ></date-picker>
          </div>
          <div class="col-md-3" v-show="endDateShow">
            <label for="validation">ถึงวันที่ :</label>
            <date-picker
              v-model="endDate"
              format="YYYY-MM-DD"
              value-type="format"
            ></date-picker>
          </div>

          <div class="col-md-3 text-end" v-show="btnSearch">
            <label for="btn">&nbsp; </label><br />
            <btnComponent
              @click="getData()"
              changeStyle="search"
            ></btnComponent>
          </div>
        </div>
        <div class="row" v-show="showTabeComponent">
          <hr />
          <tableData :fields="fields" :items="rowData">
            <template #cell(action)="rowData">
              <ul class="list-inline mb-0">
                <li class="list-inline-item"></li>
                <li class="list-inline-item">
                  <a
                    href="javascript:void(0);"
                    class="px-2 text-success"
                    v-b-tooltip.hover
                    title="print"
                    @click="getReportTb(rowData.item)"
                  >
                    <i class="uil uil-print font-size-18"></i>
                  </a>
                </li>
              </ul>
            </template>
          </tableData>
        </div>
        <hr />
        <button v-show="btnShow" class="btn btn-primary" @click="getReport()">
          <i class="uil uil-print"></i> พิมพ์รายงาน
        </button>
      </b-modal>
    </b-overlay>
  </Layout>
</template>
